/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import '@ionic/angular/css/core.css';

/* Basic CSS for apps built with Ionic */
@import '@ionic/angular/css/normalize.css';
@import '@ionic/angular/css/structure.css';
@import '@ionic/angular/css/typography.css';
@import '@ionic/angular/css/display.css';

/* Optional CSS utils that can be commented out */
@import '@ionic/angular/css/padding.css';
@import '@ionic/angular/css/float-elements.css';
@import '@ionic/angular/css/text-alignment.css';
@import '@ionic/angular/css/text-transformation.css';
@import '@ionic/angular/css/flex-utils.css';
@import './theme/elements.scss';

:root {
  --safe-area-top: env(safe-area-inset-top);
  --safe-area-bottom: env(safe-area-inset-bottom, 0);
  --safe-area-left: env(safe-area-inset-left, 0);
  --safe-area-right: env(safe-area-inset-right, 0);
}

// html.ios ion-modal.modal-card .ion-page > ion-header > ion-toolbar:first-of-type {
//   padding-top: 25px;
// }

.title {
  // font-family: San Francisco Display;
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 29px;
  align-items: center;
  text-align: center;
  margin-bottom: 15px;
  color: var(--ion-color-oscuro);
}

.subtitle {
  // font-family: San Francisco Display;
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 26px;
  align-items: center;
  /* Contenido / Azul_grisaceo */
  margin-bottom: 20px;
  color: var(--ion-color-azul-grisaceo);
}

.input {
  background: #ffffff;
  /* Estatus / No_estatus */
  border: 1px solid var(--ion-color-medium);
  box-sizing: border-box;
  box-shadow: 0px 7px 15px rgba(136, 143, 155, 0.1);
  border-radius: 10px;
}

/*Pagina de registro*/

.circleActiveStep {
  display: inline-block;
  height: 30px;
  width: 30px;
  line-height: 30px;
  -moz-border-radius: 15px;
  /* or 50% */
  border-radius: 15px;
  /* or 50% */
  background-color: var(--ion-color-naranja-acento);
  color: white;
  text-align: center;
  font-size: 1em;
}

.circleDoneStep {
  display: inline-block;
  height: 30px;
  width: 30px;
  line-height: 30px;
  -moz-border-radius: 15px;
  /* or 50% */
  border-radius: 15px;
  /* or 50% */
  border: 1px solid var(--ion-color-naranja-acento);
  color: var(--ion-color-naranja-acento);
  text-align: center;
  font-size: 1em;
}

.circlePendingStep {
  display: inline-block;
  height: 30px;
  width: 30px;
  line-height: 30px;
  -moz-border-radius: 15px;
  /* or 50% */
  border-radius: 15px;
  /* or 50% */
  border-width: 1px;
  border-style: solid;
  border-color: var(--ion-color-azul-grisaceo);
  color: var(--ion-color-azul-grisaceo);
  text-align: center;
  font-size: 1em;
}

.lineDoneStep {
  border-color: var(--ion-color-naranja-acento) !important;
}

.colorButton {
  width: 20px;
  height: 20px;
  text-align: center;
  margin-top: 12px;
}

.backButton {
  width: 50px;
  height: 50px;
  text-align: center;
}

/*Menu de Perfil de Usuarios*/

.listItemProfile {
  position: initial;
  width: 95%;
  max-width: 600px;
  height: 65px;
  left: 16px;
  top: 501px;
  /* Contenido / Blanco */
  background: #ffffff;
  box-shadow: 0px 4px 15px rgba(212, 212, 212, 0.4);
  border-radius: 10px;
}

.itemTitle {
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 19px;
  /* identical to box height */
  display: flex;
  align-items: center;
  /* Contenido / Oscuro */
  color: var(--ion-color-oscuro);
}

.itemHeader {
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
  letter-spacing: 0.03em;
  text-transform: uppercase;
  /* Contenido / Azul_grisaceo */
}

.vertical-align-content {
  display: flex !important;
  align-content: center !important;
  align-items: center !important;
}

.titleModal {
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 28px;
  /* identical to box height, or 156% */
  display: flex;
  align-items: center;
  text-align: center;
  /* Contenido / Oscuro */
  color: var(--ion-color-oscuro);
}

.seleccionable {
  cursor: pointer;
}

.fullscreen {
  // margin-top: env(safe-area-inset-top);
  // width: 100% !important;
  // height: 100% !important;
}

.gm-style .gm-style-iw-c {
  border-radius: 46px !important;
  height: 34px !important;
}

.gm-style .gm-style-iw-c::after {
  content: '|' !important;
  margin-top: -4px !important;
  font-size: 15px !important;
  transform: none !important;
  background: none !important;
}

:host(.item-interactive.ion-invalid) {
  --highlight-background: red; //var(--highlight-color-invalid);
}

#noti-btn {
  position: relative;
  width: 29px;
  top: 1px;
  right: 1px;
  overflow: visible !important;

  #noti-badge {
    background-color: var(--ion-color-negativo);
    position: absolute;
    top: 0px;
    right: -2px;
    border-radius: 50%;
    font-size: 10px;
    width: 16px;
    padding: 4px;
    height: 16px;
  }
}

@media (min-width: 768px) {
  .app-root {
    width: 768px;
    margin: auto;
    position: relative;
  }

  .scroll-content {
    overflow-y: auto;
  }

  // .pac-container {
  //   margin-top: -39px !important;
  //   left: calc(16px + 16px + 16px) !important;
  // }
}

@media (min-width: 1024px) {

  ion-content {
    --keyboard-offset: 0 !important;
  }

  .scroll-content {
    overflow-y: auto;
  }

  .pac-container {
    margin-top: -39px !important;
    left: calc(16px + 16px + 16px) !important;
  }

  .cdk-overlay-pane {
    // top: 310px !important;
    // left: 21px !important;
    left: calc(50% - 148px) !important;
  }


  .app-root {
    position: relative;
    width: 500px;
    height: 1024px;
    margin: auto;
    border: 16px black solid;
    border-top-width: 60px;
    border-bottom-width: 60px;
    border-radius: 36px;

    ion-modal {
      --width: 470px;
    }
  }

  .app-root:before {
    content: '';
    display: block;
    width: 60px;
    height: 5px;
    position: absolute;
    top: -30px;
    left: 50%;
    transform: translate(-50%, -50%);
    background: #333;
    border-radius: 10px;
  }

  /* The circle on the bottom of the device */
  .app-root:after {
    content: '';
    display: block;
    width: 35px;
    height: 35px;
    position: absolute;
    left: 50%;
    bottom: -65px;
    transform: translate(-50%, -50%);
    background: #333;
    border-radius: 50%;
    z-index: 0;
  }

  //funciona
  .BottomSheetBox {
    overflow: hidden !important;
    width: 450px !important;
  }

  .BottomSheetOuter {
    width: 468px !important;
  }

  .BottomSheetContent {
    width: 450px !important;
    overflow: hidden !important;
  }

  .ShowBackdrop {
    opacity: 0.7 !important;
  }
}

ion-modal::part(handle) {
  top: 15px !important;
}

.panic-help {
  --swiper-pagination-bullet-inactive-color: var(--ion-color-blanco);
  --swiper-pagination-color: var(--ion-color-azul);
  --swiper-pagination-progressbar-bg-color: rgba(var(--ion-text-color-rgb, 0, 0, 0), 0.25);
  --swiper-scrollbar-bg-color: rgba(var(--ion-color-casi-blanco, 0, 0, 0), 0.1);
  --swiper-scrollbar-drag-bg-color: rgba(var(--ion-text-color-rgb, 0, 0, 0), 0.5);
}


app-start {
  swiper-container {
    height: 100%;
    background: var(--ion-color-casi-blanco); //linear-gradient(360deg, #213b6b 0%, #4989ff 100%);
    --swiper-pagination-bullet-inactive-color: var(--ion-color-blanco);
    --swiper-pagination-color: var(--ion-color-azul);
    --swiper-pagination-progressbar-bg-color: rgba(var(--ion-text-color-rgb, 0, 0, 0), 0.25);
    --swiper-scrollbar-bg-color: rgba(var(--ion-color-casi-blanco, 0, 0, 0), 0.1);
    --swiper-scrollbar-drag-bg-color: rgba(var(--ion-text-color-rgb, 0, 0, 0), 0.5);
    // background: var(--ion-color-casi-blanco);
    // --bullet-background: var(--ion-color-oscuro);
    // --bullet-background-active: var(--ion-color-orange);
  }

  swiper-slide {
    display: flex;
    position: relative;

    flex-shrink: 0;
    align-items: center;
    justify-content: center;

    width: 100%;
    height: 100%;

    // font-size: 18px;

    text-align: center;
    box-sizing: border-box;

    color: #ffffff;
    font-size: 24px;
    font-weight: normal;
    padding: 0px 45px 0px 45px;
  }

  swiper-slide img {
    margin: 10px 26px 10px 26px;
    pointer-events: none;
  }
}

ion-header {
  &.op-header {
    ion-toolbar {
      --min-height: 65px;
      --max-height: 65px;
      --padding-top: 10px;
    }
  }
}

ion-tab-bar {
  height: 65px;
  // --min-height: 65px !important;
  --background: var(--ion-color-blanco);

  ion-tab-button {
    // --background: #ffffff;
    // --padding-bottom: 10px;
    // --padding-top: 10px;
    --min-height: 65px !important;
    --color: var(--ion-color-azul-grsaceo);
    --color-selected: var(--ion-color-azul);

    ion-icon {
      font-size: 30px;
    }

    &.tab-selected {
      .label-tiny {
        font-weight: 600;
      }
    }
  }
}

.bar-padding-horizonal {
  --padding-start: 16px;
  --padding-end: 16px;
}

.autoheight {
  --height: auto;
}

.m-10 {
  margin: 10px;
}

.m-20 {
  margin: 20px;
}

.m-40 {
  margin: 40px;
}

.mx-5 {
  margin-left: 5px;
  margin-right: 5px;
}

.mx-10 {
  margin-left: 10px;
  margin-right: 10px;
}

.my-10 {
  margin-top: 10px;
  margin-bottom: 10px;
}

.mx-20 {
  margin-left: 20px;
  margin-right: 20px;
}

.my-20 {
  margin-top: 20px;
  margin-bottom: 20px;
}

.mx-40 {
  margin-left: 40px;
  margin-right: 40px;
}

.my-40 {
  margin-top: 40px;
  margin-bottom: 40px;
}

.mt-10 {
  margin-top: 10px;
}

.mt-20 {
  margin-top: 20px;
}

.mt-40 {
  margin-top: 40px;
}

.ml-10 {
  margin-left: 10px;
}

.ml-20 {
  margin-left: 20px;
}

.ml-40 {
  margin-left: 40px;
}

.mr-10 {
  margin-right: 10px;
}

.mr-20 {
  margin-right: 20px;
}

.mr-40 {
  margin-right: 40px;
}

.mb-10 {
  margin-bottom: 10px;
}

.mb-20 {
  margin-bottom: 20px;
}

.mb-40 {
  margin-bottom: 40px;
}

ion-item-divider {
  margin-top: 0px;
  min-height: 1px !important;
}

.flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.panic_style {
  color: var(--ion-color-negativo);
}

app-info-panic-modal {
  swiper-container {
    // height: 100%;
    // background: linear-gradient(360deg, #213b6b 0%, #4989ff 100%);
    --swiper-pagination-bullet-inactive-color: var(--ion-color-medium);
    --swiper-pagination-color: var(--ion-color-orange, #3880ff);
    --swiper-pagination-progressbar-bg-color: rgba(var(--ion-text-color-rgb, 0, 0, 0), 0.25);
    --swiper-scrollbar-bg-color: rgba(var(--ion-color-medium, 0, 0, 0), 0.1);
    --swiper-scrollbar-drag-bg-color: rgba(var(--ion-text-color-rgb, 0, 0, 0), 0.5);
    // background: var(--ion-color-casi-blanco);
    // --bullet-background: var(--ion-color-oscuro);
    // --bullet-background-active: var(--ion-color-orange);
  }
}