// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@use '@angular/material' as mat;
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat.core();

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$app-primary: mat.define-palette(mat.$indigo-palette);
$app-accent: mat.define-palette(mat.$pink-palette, A200, A100, A400);

// The warn palette is optional (defaults to red).
$app-warn: mat.define-palette(mat.$red-palette);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$app-theme: mat.define-light-theme((color: (primary: $app-primary,
        accent: $app-accent,
        warn: $app-warn,
      ),
    ));

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-component-themes($app-theme);

// Ionic Variables and Theming. For more info, please see:
// http://ionicframework.com/docs/theming/

/** Ionic CSS Variables **/
:root {
  /** primary **/
  --ion-color-primary: #3880ff;
  --ion-color-primary-rgb: 56, 128, 255;
  --ion-color-primary-contrast: #ffffff;
  --ion-color-primary-contrast-rgb: 255, 255, 255;
  --ion-color-primary-shade: #3171e0;
  --ion-color-primary-tint: #4c8dff;

  /** secondary **/
  --ion-color-secondary: #3dc2ff;
  --ion-color-secondary-rgb: 61, 194, 255;
  --ion-color-secondary-contrast: #ffffff;
  --ion-color-secondary-contrast-rgb: 255, 255, 255;
  --ion-color-secondary-shade: #36abe0;
  --ion-color-secondary-tint: #50c8ff;

  /** tertiary **/
  --ion-color-tertiary: #5260ff;
  --ion-color-tertiary-rgb: 82, 96, 255;
  --ion-color-tertiary-contrast: #ffffff;
  --ion-color-tertiary-contrast-rgb: 255, 255, 255;
  --ion-color-tertiary-shade: #4854e0;
  --ion-color-tertiary-tint: #6370ff;

  /** success **/
  --ion-color-success: #2dd36f;
  --ion-color-success-rgb: 45, 211, 111;
  --ion-color-success-contrast: #ffffff;
  --ion-color-success-contrast-rgb: 255, 255, 255;
  --ion-color-success-shade: #28ba62;
  --ion-color-success-tint: #42d77d;

  /** warning **/
  --ion-color-warning: #ffc409;
  --ion-color-warning-rgb: 255, 196, 9;
  --ion-color-warning-contrast: #000000;
  --ion-color-warning-contrast-rgb: 0, 0, 0;
  --ion-color-warning-shade: #e0ac08;
  --ion-color-warning-tint: #ffca22;

  /** danger **/
  --ion-color-danger: #eb445a;
  --ion-color-danger-rgb: 235, 68, 90;
  --ion-color-danger-contrast: #ffffff;
  --ion-color-danger-contrast-rgb: 255, 255, 255;
  --ion-color-danger-shade: #cf3c4f;
  --ion-color-danger-tint: #ed576b;

  /** dark **/
  --ion-color-dark: #222428;
  --ion-color-dark-rgb: 34, 36, 40;
  --ion-color-dark-contrast: #ffffff;
  --ion-color-dark-contrast-rgb: 255, 255, 255;
  --ion-color-dark-shade: #1e2023;
  --ion-color-dark-tint: #383a3e;
  /** medium **/
  --ion-color-medium: #d5d5d5;
  --ion-color-medium-rgb: 213, 213, 213;
  --ion-color-medium-contrast: #213b6b;
  --ion-color-medium-contrast-rgb: 33, 59, 107;
  --ion-color-medium-shade: #bbbbbb;
  --ion-color-medium-tint: #d9d9d9;
  /** light **/
  --ion-color-light: #f4f5f8;
  --ion-color-light-rgb: 244, 245, 248;
  --ion-color-light-contrast: #000000;
  --ion-color-light-contrast-rgb: 0, 0, 0;
  --ion-color-light-shade: #d7d8da;
  --ion-color-light-tint: #f5f6f9;
  /** azul primario **/
  --ion-color-azul: #235B4E;
  --ion-color-azul-rgb: 35, 91, 78;
  --ion-color-azul-contrast: #ffffff;
  --ion-color-azul-contrast-rgb: 255, 255, 255;
  --ion-color-azul-shade: #1f5045;
  --ion-color-azul-tint: #396b60;
  /** azul acento **/
  --ion-color-azul-acento: #235B4E;
  --ion-color-azul-acento--rgb: 35, 91, 78;
  --ion-color-azul-acento-contrast: #ffffff;
  --ion-color-azul-acento-contrast-rgb: 255, 255, 255;
  --ion-color-azul-acento-shade: #1f5045;
  --ion-color-azul-acento-tint: #396b60;
  /**naranja acento**/
  --ion-color-naranja-acento: #235B4E;
  --ion-color-naranja-acento-rgb: 35, 91, 78;
  --ion-color-naranja-acento-contrast: #ffffff;
  --ion-color-naranja-acento-contrast-rgb: 255, 255, 255;
  --ion-color-naranja-acento-shade: #1f5045;
  --ion-color-naranja-acento-tint: #396b60;
  /** positivo **/
  --ion-color-positivo: #235B4E;
  --ion-color-positivo-rgb: 35, 91, 78;
  --ion-color-positivo-contrast: #ffffff;
  --ion-color-positivo-contrast-rgb: 255, 255, 255;
  --ion-color-positivo-shade: #1f5045;
  --ion-color-positivo-tint: #396b60;
  /** alerta **/
  --ion-color-alerta: #F49739;
  --ion-color-alerta-rgb: 244, 151, 57;
  --ion-color-alerta-contrast: #000000;
  --ion-color-alerta-contrast-rgb: 0, 0, 0;
  --ion-color-alerta-shade: #d78532;
  --ion-color-alerta-tint: #f5a14d;

  /** negativo **/
  --ion-color-negativo: #9F2240;
  --ion-color-negativo-rgb: 159, 34, 64;
  --ion-color-negativo-contrast: #ffffff;
  --ion-color-negativo-contrast-rgb: 255, 255, 255;
  --ion-color-negativo-shade: #8c1e38;
  --ion-color-negativo-tint: #a93853;
  /** no estatus **/
  --ion-color-no-estatus: #d4d4d4;
  --ion-color-no-estatus-rgb: 212, 212, 212;
  --ion-color-no-estatus-contrast: #030a17;
  --ion-color-no-estatus-contrast-rgb: 3, 10, 23;
  --ion-color-no-estatus-shade: #bbbbbb;
  --ion-color-no-estatus-tint: #d8d8d8;
  /** oscuro **/
  --ion-color-oscuro: #030a17;
  --ion-color-oscuro-rgb: 3, 10, 23;
  --ion-color-oscuro-contrast: #ffffff;
  --ion-color-oscuro-contrast-rgb: 255, 255, 255;
  --ion-color-oscuro-shade: #030914;
  --ion-color-oscuro-tint: #1c232e;
  /** blanco **/
  --ion-color-blanco: #ffffff;
  --ion-color-blanco-rgb: 255, 255, 255;
  --ion-color-blanco-contrast: #030a17;
  --ion-color-blanco-contrast-rgb: 3, 10, 23;
  --ion-color-blanco-shade: #e0e0e0;
  --ion-color-blanco-tint: #ffffff;
  /** casi blanco **/
  --ion-color-casi-blanco: #eaeff6;
  --ion-color-casi-blanco-rgb: 234, 239, 246;
  --ion-color-casi-blanco-contrast: #030a17;
  --ion-color-casi-blanco-contrast-rgb: 3, 10, 23;
  --ion-color-casi-blanco-shade: #ced2d8;
  --ion-color-casi-blanco-tint: #ecf1f7;
  /** azul grisaceo **/
  --ion-color-azul-grisaceo: #888f9b;
  --ion-color-azul-grisaceo-rgb: 136, 143, 155;
  --ion-color-azul-grisaceo-contrast: #030a17;
  --ion-color-azul-grisaceo-contrast-rgb: 3, 10, 23;
  --ion-color-azul-grisaceo-shade: #787e88;
  --ion-color-azul-grisaceo-tint: #949aa5;
  /** orange strong - naranja acento **/
  --ion-color-orange: #ff5912;
  --ion-color-orange-rgb: 255, 89, 18;
  --ion-color-orange-contrast: #ffffff;
  --ion-color-orange-contrast-rgb: 255, 255, 255;
  --ion-color-orange-shade: #e04e10;
  --ion-color-orange-tint: #ff6a2a;
  /** orange light **/
  --ion-color-orange-light: #ffa040;
  --ion-color-orange-light-rgb: 255, 160, 64;
  --ion-color-orange-light-contrast: #ffffff;
  --ion-color-orange-light-contrast-rgb: 255, 255, 255;
  --ion-color-orange-light-shade: #e04e10;
  --ion-color-orange-light-tint: #ffaa53;

  //gold
  --ion-color-gold: #DDC9A3;
  --ion-color-gold-rgb: 221, 201, 163;
  --ion-color-gold-contrast: #000000;
  --ion-color-gold-contrast-rgb: 0, 0, 0;
  --ion-color-gold-shade: #c2b18f;
  --ion-color-gold-tint: #e0ceac;


  --ion-font-family: 'Roboto';
  --ion-grid-columns: 6;
  --border-radius: 50px;
  --ion-background-color: #ffffff;

  ion-button {
    text-transform: none;
  }
}

.ion-color-azul {
  --ion-color-base: var(--ion-color-azul);
  --ion-color-base-rgb: var(--ion-color-azul-rgb);
  --ion-color-contrast: var(--ion-color-azul-contrast);
  --ion-color-contrast-rgb: var(--ion-color-azul-contrast-rgb);
  --ion-color-shade: var(--ion-color-azul-shade);
  --ion-color-tint: var(--ion-color-azul-tint);
}

.ion-color-azul-acento {
  --ion-color-base: var(--ion-color-azul-acento);
  --ion-color-base-rgb: var(--ion-color-azul-acento-rgb);
  --ion-color-contrast: var(--ion-color-azul-acento-contrast);
  --ion-color-contrast-rgb: var(--ion-color-azul-acento-contrast-rgb);
  --ion-color-shade: var(--ion-color-azul-acento-shade);
  --ion-color-tint: var(--ion-color-azul-acento-tint);
}

.ion-color-naranja-gradiente {
  --ion-color-base: linear-gradient(90deg,
      var(--ion-color-orange) 0%,
      var(--ion-color-orange-light) 100%);
  --ion-color-base-rgb: linear-gradient(90deg,
      var(--ion-color-orange-rgb) 0%,
      var(--ion-color-orange-light-rgb) 100%);
  --ion-color-contrast: var(--ion-color-orange-contrast);
  --ion-color-contrast-rgb: var(--ion-color-orange-contrast-rgb);
  --ion-color-shade: var(--ion-color-orange-shade);
  --ion-color-tint: var(--ion-color-orange-tint);
}

.ion-color-naranja-acento {
  --ion-color-base: var(--ion-color-naranja-acento);
  --ion-color-base-rgb: var(--ion-color-naranja-acento-rgb);
  --ion-color-contrast: var(--ion-color-naranja-acento-contrast);
  --ion-color-contrast-rgb: var(--ion-color-naranja-acento-contrast-rgb);
  --ion-color-shade: var(--ion-color-naranja-acento-shade);
  --ion-color-tint: var(--ion-color-naranja-acento-tint);
}

.ion-color-positivo {
  --ion-color-base: var(--ion-color-positivo);
  --ion-color-base-rgb: var(--ion-color-positivo-rgb);
  --ion-color-contrast: var(--ion-color-positivo-contrast);
  --ion-color-contrast-rgb: var(--ion-color-positivo-contrast-rgb);
  --ion-color-shade: var(--ion-color-positivo-shade);
  --ion-color-tint: var(--ion-color-positivo-tint);
}

.ion-color-alerta {
  --ion-color-base: var(--ion-color-alerta);
  --ion-color-base-rgb: var(--ion-color-alerta-rgb);
  --ion-color-contrast: var(--ion-color-alerta-contrast);
  --ion-color-contrast-rgb: var(--ion-color-alerta-contrast-rgb);
  --ion-color-shade: var(--ion-color-alerta-shade);
  --ion-color-tint: var(--ion-color-alerta-tint);
}

.ion-color-negativo {
  --ion-color-base: var(--ion-color-negativo);
  --ion-color-base-rgb: var(--ion-color-negativo-rgb);
  --ion-color-contrast: var(--ion-color-negativo-contrast);
  --ion-color-contrast-rgb: var(--ion-color-negativo-contrast-rgb);
  --ion-color-shade: var(--ion-color-negativo-shade);
  --ion-color-tint: var(--ion-color-negativo-tint);
}

.ion-color-no-estatus {
  --ion-color-base: var(--ion-color-no-estatus);
  --ion-color-base-rgb: var(--ion-color-no-estatus-rgb);
  --ion-color-contrast: var(--ion-color-no-estatus-contrast);
  --ion-color-contrast-rgb: var(--ion-color-no-estatus-contrast-rgb);
  --ion-color-shade: var(--ion-color-no-estatus-shade);
  --ion-color-tint: var(--ion-color-no-estatus-tint);
}

.ion-color-oscuro {
  --ion-color-base: var(--ion-color-oscuro);
  --ion-color-base-rgb: var(--ion-color-oscuro-rgb);
  --ion-color-contrast: var(--ion-color-oscuro-contrast);
  --ion-color-contrast-rgb: var(--ion-color-oscuro-contrast-rgb);
  --ion-color-shade: var(--ion-color-oscuro-shade);
  --ion-color-tint: var(--ion-color-oscuro-tint);
}

.ion-color-blanco {
  --ion-color-base: var(--ion-color-blanco);
  --ion-color-base-rgb: var(--ion-color-blanco-rgb);
  --ion-color-contrast: var(--ion-color-blanco-contrast);
  --ion-color-contrast-rgb: var(--ion-color-blanco-contrast-rgb);
  --ion-color-shade: var(--ion-color-blanco-shade);
  --ion-color-tint: var(--ion-color-blanco-tint);
}

.ion-color-casi-blanco {
  --ion-color-base: var(--ion-color-casi-blanco);
  --ion-color-base-rgb: var(--ion-color-casi-blanco-rgb);
  --ion-color-contrast: var(--ion-color-casi-blanco-contrast);
  --ion-color-contrast-rgb: var(--ion-color-casi-blanco-contrast-rgb);
  --ion-color-shade: var(--ion-color-casi-blanco-shade);
  --ion-color-tint: var(--ion-color-casi-blanco-tint);
}

.ion-color-azul-grisaceo {
  --ion-color-base: var(--ion-color-azul-grisaceo);
  --ion-color-base-rgb: var(--ion-color-azul-grisaceo-rgb);
  --ion-color-contrast: var(--ion-color-azul-grisaceo-contrast);
  --ion-color-contrast-rgb: var(--ion-color-azul-grisaceo-contrast-rgb);
  --ion-color-shade: var(--ion-color-azul-grisaceo-shade);
  --ion-color-tint: var(--ion-color-azul-grisaceo-tint);
}

.ion-color-gris {
  --ion-color-base: var(--ion-color-medium);
  --ion-color-base-rgb: var(--ion-color-medium-rgb);
  --ion-color-contrast: var(--ion-color-medium-contrast);
  --ion-color-contrast-rgb: var(--ion-color-medium-contrast-rgb);
  --ion-color-shade: var(--ion-color-medium-shade);
  --ion-color-tint: var(--ion-color-medium-tint);
  // --color: var(--ion-color-medium-contrast)
}

.ion-color-gold {
  --ion-color-base: var(--ion-color-gold);
  --ion-color-base-rgb: var(--ion-color-gold-rgb);
  --ion-color-contrast: var(--ion-color-gold-contrast);
  --ion-color-contrast-rgb: var(--ion-color-gold-contrast-rgb);
  --ion-color-shade: var(--ion-color-gold-shade);
  --ion-color-tint: var(--ion-color-gold-tint);
}

// $grid-breakpoints: (
//  xs: 0, // Phone (0px - 575px)
//  sm: 576px, // Phablet (576px - 767px)
//  md: 768px, // Tablet vertical (768px - 991px)
//  lg: 992px // Tablet horizontal, Desktop (992px and above)
// );

// $grid-max-widths: (
//  lg: 992px
// );

@font-face {
  font-family: 'Roboto';
  src: url('../assets/fonts/roboto/Roboto-Bold.ttf');
}

@font-face {
  font-family: 'Roboto';
  src: url('../assets/fonts/roboto/Roboto-Medium.ttf');
}

@font-face {
  font-family: 'Roboto';
  src: url('../assets/fonts/roboto/Roboto-Regular.ttf');
}

@font-face {
  font-family: 'SFProDisplay';
  src: url('../assets/fonts/sf/SFProDisplay-Bold.ttf');
  font-weight: bold;
}

@font-face {
  font-family: 'SFProDisplay';
  src: url('../assets/fonts/sf/SFProDisplay-Medium.ttf');
  font-weight: 600;
}

@font-face {
  font-family: 'SFProDisplay';
  src: url('../assets/fonts/sf/SFProDisplay-Regular.ttf');
  font-weight: normal;
}

@font-face {
  font-family: 'SFProDisplay';
  src: url('../assets/fonts/sf/SFProDisplay-Semibold.ttf');
  font-weight: lighter;
}

@media (prefers-color-scheme: dark) {
  /*
   * Dark Colors
   * -------------------------------------------
   */

  // body {
  //   --ion-color-primary: #428cff;
  //   --ion-color-primary-rgb: 66,140,255;
  //   --ion-color-primary-contrast: #ffffff;
  //   --ion-color-primary-contrast-rgb: 255,255,255;
  //   --ion-color-primary-shade: #3a7be0;
  //   --ion-color-primary-tint: #5598ff;

  //   --ion-color-secondary: #50c8ff;
  //   --ion-color-secondary-rgb: 80,200,255;
  //   --ion-color-secondary-contrast: #ffffff;
  //   --ion-color-secondary-contrast-rgb: 255,255,255;
  //   --ion-color-secondary-shade: #46b0e0;
  //   --ion-color-secondary-tint: #62ceff;

  //   --ion-color-tertiary: #6a64ff;
  //   --ion-color-tertiary-rgb: 106,100,255;
  //   --ion-color-tertiary-contrast: #ffffff;
  //   --ion-color-tertiary-contrast-rgb: 255,255,255;
  //   --ion-color-tertiary-shade: #5d58e0;
  //   --ion-color-tertiary-tint: #7974ff;

  //   --ion-color-success: #2fdf75;
  //   --ion-color-success-rgb: 47,223,117;
  //   --ion-color-success-contrast: #000000;
  //   --ion-color-success-contrast-rgb: 0,0,0;
  //   --ion-color-success-shade: #29c467;
  //   --ion-color-success-tint: #44e283;

  //   --ion-color-warning: #ffd534;
  //   --ion-color-warning-rgb: 255,213,52;
  //   --ion-color-warning-contrast: #000000;
  //   --ion-color-warning-contrast-rgb: 0,0,0;
  //   --ion-color-warning-shade: #e0bb2e;
  //   --ion-color-warning-tint: #ffd948;

  //   --ion-color-danger: #ff4961;
  //   --ion-color-danger-rgb: 255,73,97;
  //   --ion-color-danger-contrast: #ffffff;
  //   --ion-color-danger-contrast-rgb: 255,255,255;
  //   --ion-color-danger-shade: #e04055;
  //   --ion-color-danger-tint: #ff5b71;

  //   --ion-color-dark: #f4f5f8;
  //   --ion-color-dark-rgb: 244,245,248;
  //   --ion-color-dark-contrast: #000000;
  //   --ion-color-dark-contrast-rgb: 0,0,0;
  //   --ion-color-dark-shade: #d7d8da;
  //   --ion-color-dark-tint: #f5f6f9;

  //   --ion-color-medium: #989aa2;
  //   --ion-color-medium-rgb: 152,154,162;
  //   --ion-color-medium-contrast: #000000;
  //   --ion-color-medium-contrast-rgb: 0,0,0;
  //   --ion-color-medium-shade: #86888f;
  //   --ion-color-medium-tint: #a2a4ab;

  //   --ion-color-light: #222428;
  //   --ion-color-light-rgb: 34,36,40;
  //   --ion-color-light-contrast: #ffffff;
  //   --ion-color-light-contrast-rgb: 255,255,255;
  //   --ion-color-light-shade: #1e2023;
  //   --ion-color-light-tint: #383a3e;
  // }

  // /*
  //  * iOS Dark Theme
  //  * -------------------------------------------
  //  */

  // .ios body {
  //   --ion-background-color: #000000;
  //   --ion-background-color-rgb: 0,0,0;

  //   --ion-text-color: #ffffff;
  //   --ion-text-color-rgb: 255,255,255;

  //   --ion-color-step-50: #0d0d0d;
  //   --ion-color-step-100: #1a1a1a;
  //   --ion-color-step-150: #262626;
  //   --ion-color-step-200: #333333;
  //   --ion-color-step-250: #404040;
  //   --ion-color-step-300: #4d4d4d;
  //   --ion-color-step-350: #595959;
  //   --ion-color-step-400: #666666;
  //   --ion-color-step-450: #737373;
  //   --ion-color-step-500: #808080;
  //   --ion-color-step-550: #8c8c8c;
  //   --ion-color-step-600: #999999;
  //   --ion-color-step-650: #a6a6a6;
  //   --ion-color-step-700: #b3b3b3;
  //   --ion-color-step-750: #bfbfbf;
  //   --ion-color-step-800: #cccccc;
  //   --ion-color-step-850: #d9d9d9;
  //   --ion-color-step-900: #e6e6e6;
  //   --ion-color-step-950: #f2f2f2;

  //   --ion-item-background: #000000;

  //   --ion-card-background: #1c1c1d;
  // }

  // .ios ion-modal {
  //   --ion-background-color: var(--ion-color-step-100);
  //   --ion-toolbar-background: var(--ion-color-step-150);
  //   --ion-toolbar-border-color: var(--ion-color-step-250);
  // }

  // /*
  //  * Material Design Dark Theme
  //  * -------------------------------------------
  //  */

  // .md body {
  //   --ion-background-color: #121212;
  //   --ion-background-color-rgb: 18,18,18;

  //   --ion-text-color: #ffffff;
  //   --ion-text-color-rgb: 255,255,255;

  //   --ion-border-color: #222222;

  //   --ion-color-step-50: #1e1e1e;
  //   --ion-color-step-100: #2a2a2a;
  //   --ion-color-step-150: #363636;
  //   --ion-color-step-200: #414141;
  //   --ion-color-step-250: #4d4d4d;
  //   --ion-color-step-300: #595959;
  //   --ion-color-step-350: #656565;
  //   --ion-color-step-400: #717171;
  //   --ion-color-step-450: #7d7d7d;
  //   --ion-color-step-500: #898989;
  //   --ion-color-step-550: #949494;
  //   --ion-color-step-600: #a0a0a0;
  //   --ion-color-step-650: #acacac;
  //   --ion-color-step-700: #b8b8b8;
  //   --ion-color-step-750: #c4c4c4;
  //   --ion-color-step-800: #d0d0d0;
  //   --ion-color-step-850: #dbdbdb;
  //   --ion-color-step-900: #e7e7e7;
  //   --ion-color-step-950: #f3f3f3;

  //   --ion-item-background: #1e1e1e;

  //   --ion-toolbar-background: #1f1f1f;

  //   --ion-tab-bar-background: #1f1f1f;

  //   --ion-card-background: #1e1e1e;
  // }
}

html,
body {
  height: 100%;
}

body {
  margin: 0;
  font-family: Roboto, 'Helvetica Neue', sans-serif;
}