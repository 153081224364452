.label-hs {
  font-family: 'SFProDisplay' !important;
  font-style: normal !important;
  font-weight: 600 !important;
  font-size: 35px !important;
  line-height: 45px !important;
}

.label-hm {
  font-family: 'SFProDisplay' !important;
  font-style: normal;
  font-weight: 500 !important;
  font-size: 24px !important;
  line-height: 29px !important;
}

.label-hr {
  font-family: 'SFProDisplay' !important;
  font-style: normal !important;
  font-weight: normal !important;
  font-size: 24px !important;
  line-height: 29px !important;
}

.label-ss {
  font-family: 'SFProDisplay' !important;
  font-style: normal;
  font-weight: 600 !important;
  font-size: 20px !important;
  line-height: 26px !important;
}

.label-sr {
  font-family: 'SFProDisplay' !important;
  font-style: normal !important;
  font-weight: normal !important;
  font-size: 20px !important;
  line-height: 26px !important;
}

.label-pm {
  font-family: 'SFProDisplay' !important;
  font-style: normal !important;
  font-weight: normal !important;
  font-size: 18px !important;
  line-height: 28px !important;
}

.label-pr {
  font-family: 'SFProDisplay' !important;
  font-style: normal !important;
  font-weight: normal !important;
  font-size: 16px !important;
  line-height: 24px !important;
}

.label-ps {
  font-family: 'SFProDisplay' !important;
  font-style: normal !important;
  font-weight: normal !important;
  font-size: 14px !important;
  line-height: 17px !important;
}

.label-cta-t {
  font-family: 'SFProDisplay' !important;
  font-style: normal !important;
  font-weight: bold !important;
  font-size: 20px !important;
  line-height: 26px !important;
}

.label-cta-r {
  font-family: 'SFProDisplay' !important;
  font-style: normal !important;
  font-weight: bold !important;
  font-size: 16px !important;
  line-height: 19px !important;
}

.label-cta-r-nb {
  font-family: 'SFProDisplay' !important;
  font-style: normal !important;
  font-weight: normal !important;
  font-size: 16px !important;
  line-height: 19px !important;
}

.label-ct {
  font-family: 'SFProDisplay' !important;
  font-style: normal !important;
  font-weight: 600 !important;
  font-size: 18px !important;
  line-height: 28px !important;
}

.label-d {
  font-family: 'SFProDisplay';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
}

.label-tcn {
  font-family: 'SFProDisplay';
  font-style: normal;
  font-weight: 600;
  font-size: 25px;
  line-height: 30px;
}

.label-tct {
  font-family: 'SFProDisplay';
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 24px;
}

.label-cp {
  font-family: 'SFProDisplay';
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  /* identical to box height */
  display: flex;
  align-items: center;
  /* Contenido / Oscuro */
  color: #030a17;
  padding-left: 5px;
  padding-right: 5px;
}

.label {
  font-family: 'SFProDisplay' !important;
  font-style: normal !important;
  font-weight: 500 !important;
  font-size: 12px !important;
  line-height: 14px !important;
  letter-spacing: 0.03em !important;
  text-transform: uppercase !important;
}

.link {
  text-decoration: unset;
  color: var(--ion-color-azul-acento);
}

ion-input.custom {
  --background: ##ffffff;
  // --color: #fff;
  --placeholder-color: #ddd;
  --placeholder-opacity: 0.8;

  --padding-bottom: 10px;
  --padding-end: 10px;
  --padding-start: 10px;
  --padding-top: 10px;

  --border-color: #d5d5d5;
  --border-width: 1px;
  --border-style: solid;
  box-shadow: 0px 7px 15px rgba(136, 143, 155, 0.1);
}

ion-input.custom .helper-text,
ion-input.custom .counter {
  color: var(--ion-color-step-700, #373737);
}

ion-item {
  &.input-base {
    height: 57px;
    --border-color: transparent !important;
    --padding-start: 15px;
    --inner-padding-end: 15px;
    --inner-padding-start: 10px;
    border: 1px solid #d5d5d5;
    box-sizing: border-box;
    box-shadow: 0px 7px 15px rgba(136, 143, 155, 0.1);
    border-radius: 10px;

    --highlight-color-invalid: transparent !important;
    --highlight-color-valid: transparent !important;
    --highlight-height: 0;

    &.item-has-focus {
      border: 1px solid rgba(var(--ion-color-naranja-acento-rgb), 0.5);
      box-sizing: border-box;
      box-shadow: 0px 5px 7px rgba(var(--ion-color-naranja-acento-rgb), 0.15);
    }

    ion-label {
      color: #888f9b !important;
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 19px;
      // font-size: 1rem!important;
    }

    ion-icon {
      height: 16px;
      width: 16px;
      padding: 20.5px 0px;
      margin: 0px;
    }

    ion-input {
      caret-color: var(--ion-color-oscuro) !important;
    }

  }

  &.input-bigger {
    height: 6rem;
    --border-color: transparent !important;
    --highlight-height: 0;
    --padding-start: 15px;
    --inner-padding-end: 15px;
    --inner-padding-start: 10px;
    border: 1px solid #d5d5d5;
    box-sizing: border-box;
    box-shadow: 0px 7px 15px rgba(136, 143, 155, 0.1);
    border-radius: 10px;

    &.item-has-focus {
      border: 1px solid rgba(var(--ion-color-orange-rgb), 0.5);
      box-sizing: border-box;
      box-shadow: 0px 5px 7px rgba(var(--ion-color-orange-rgb), 0.15);
    }

    ion-label {
      color: #888f9b !important;
      font-style: normal;
      font-weight: 400;
      font-size: 1.5rem !important;
      line-height: 42px;
    }

    ion-icon {
      height: 1rem;
      width: 1rem;
      padding: 20.5px 0px;
      margin: 0px;
      margin-top: 25px;
    }
  }
}

ion-item {
  &.select-base {
    height: 57px;
    border-radius: 10px;
    --color: #888f9b;
    --placeholder-color: #888f9b;
    --border-color: transparent !important;
    --highlight-height: 0;
    --padding-start: 15px;
    --inner-padding-end: 15px;
    --inner-padding-start: 10px;
    border: 1px solid #d5d5d5;
    box-sizing: border-box;
    box-shadow: 0px 7px 15px rgba(136, 143, 155, 0.1);

    &.item-has-focus {
      border: 1px solid rgba(var(--ion-color-orange-rgb), 0.5);
      box-sizing: border-box;
      box-shadow: 0px 5px 7px rgba(var(--ion-color-orange-rgb), 0.15);
    }

    // ion-label {
    //   color: #888f9b !important;
    //   font-style: normal;
    //   font-weight: normal;
    //   font-size: 16px;
    //   line-height: 19px;
    //   // font-size: 1rem!important;
    // }
    ion-icon {
      height: 16px;
      width: 16px;
      padding: 20.5px 0px;
      margin: 0px;
    }
  }
}

ion-item {
  &.area-base {
    border-radius: 10px;
    --color: #888f9b;
    --placeholder-color: #888f9b;
    --border-color: transparent !important;
    --highlight-height: 0;
    --padding-start: 15px;
    --inner-padding-end: 15px;
    --inner-padding-start: 10px;
    border: 1px solid #d5d5d5;
    box-sizing: border-box;
    box-shadow: 0px 7px 15px rgba(136, 143, 155, 0.1);

    --highlight-color-invalid: transparent !important;
    --highlight-color-valid: transparent !important;
    --highlight-height: 0;

    &.item-has-focus {
      border: 1px solid rgba(var(--ion-color-azul-rgb), 0.5);
      box-sizing: border-box;
      box-shadow: 0px 5px 7px rgba(var(--ion-color-azul-rgb), 0.15);
    }

    // ion-label {
    //   color: #888f9b !important;
    //   font-style: normal;
    //   font-weight: normal;
    //   font-size: 16px;
    //   line-height: 19px;
    //   // font-size: 1rem!important;
    // }
    ion-icon {
      height: 16px;
      width: 16px;
      padding: 20.5px 0px;
      margin: 0px;
    }

    ion-input {
      caret-color: var(--ion-color-oscuro) !important;
    }
  }
}

ion-button {
  height: 49px;

  &.btn-gradiente {
    font-weight: bold;
    --background: var(--ion-color-azul); //linear-gradient(90deg, var(--ion-color-orange) 0%, var(--ion-color-orange-light) 100%);
    --color: var(--ion-color-blanco);
    --box-shadow: 0px 4px 15px rgba(var(--ion-color-azul-rgb), 0.3);
    --background-activated: var(--ion-color-azul);
    --ripple-color: var(--ion-color-azul);
    --padding-top: 15px;
    --padding-bottom: 15px;
    --padding-start: 15px;
    --padding-end: 15px;
  }

  &.btn-outline {
    font-weight: bold;
    --background: var(--ion-color-blanco);
    --color: var(--ion-color-azul);
    --border-color: var(--ion-color-medium);
    --border-width: 1px;
    --border-style: solid;
    box-sizing: border-box;
    // --box-shadow: 0px 4px 15px rgba(var(--ion-color-azul-acento-rgb), 0.4);
    --background-activated: #eaeff7;
    // --ripple-color:  #13274B;
    --padding-top: 15px;
    --padding-bottom: 15px;
    --padding-start: 15px;
    --padding-end: 15px;
  }

  &.btn-outline-sm {
    height: 42px;
    font-weight: bold;
    --background: var(--ion-color-blanco);
    --color: var(--ion-color-azul);
    --border-color: var(--ion-color-medium);
    --border-width: 1px;
    --border-style: solid;
    box-sizing: border-box;
    // --box-shadow: 0px 4px 15px rgba(var(--ion-color-azul-acento-rgb), 0.4);
    --background-activated: #eaeff7;
    // --ripple-color:  #13274B;
    --padding-top: 11.5px;
    --padding-bottom: 11.5px;
    --padding-start: 17px;
    --padding-end: 17px;
    // --inner-padding-end: 17px;
    --inner-padding-start: 5px;

    ion-icon {
      height: 24px;
      width: 26px;
      padding: 9px 0px;
      margin: 0px;
    }
  }

  &.btn-panic-sm {
    height: 60px;
    font-weight: bold;
    font-size: 25px;
    --background: var(--ion-color-negativo);
    --color: var(--ion-color-blanco);
    --border-color: var(--ion-color-negativo);
    --border-width: 1px;
    --border-style: solid;
    box-sizing: border-box;
    // --box-shadow: 0px 4px 15px rgba(var(--ion-color-azul-acento-rgb), 0.4);
    --background-activated: #eaeff7;
    // --ripple-color:  #13274B;
    --padding-top: 11.5px;
    --padding-bottom: 11.5px;
    --padding-start: 20px;
    --padding-end: 20px;
    // --inner-padding-end: 17px;
    --inner-padding-start: 5px;

    ion-icon {
      height: 24px;
      width: 26px;
      padding: 9px 0px;
      margin: 0px;
    }
  }

  &.btn-txt-icon {
    height: 42px;
    font-weight: bold;
    --background: var(--ion-color-blanco);
    --color: var(--ion-color-azul);
    --border-color: var(--ion-color-blanco);
    // --border-width: 1px;
    // --border-style: solid;
    // box-sizing: border-box;
    // --box-shadow: 0px 4px 15px rgba(var(--ion-color-azul-acento-rgb), 0.4);
    --background-activated: #eaeff7;
    // --ripple-color:  #13274B;
    --padding-start: 10px;
    --padding-end: 10px;
    // --inner-padding-end: 17px;
    --inner-padding-start: 5px;

    ion-icon {
      height: 24px;
      width: 24px;
      padding: 8px 0px;
      margin: 5px;
    }
  }

  &.btn-azul {
    font-weight: bold;
    --background: var(--ion-color-azul);
    --color: var(--ion-color-blanco);
    --box-shadow: 0px 4px 15px rgba(var(--ion-color-azul-acento-rgb), 0.4);
    --background-activated: #13274b;
    --ripple-color: #13274b;
  }
}

ion-toggle {
  // --border-radius:100px;
  // --handle-height: 28px;
  // --handle-width: 28px;
  --handle-box-shadow: 0px 3px 1px rgba(0, 0, 0, 0.1), 0px 1px 1px rgba(0, 0, 0, 0.16), 0px 3px 8px rgba(0, 0, 0, 0.15);
  // --background: #FFFFFF;
  --background-checked: #4cd964;
  --handle-background: #ffffff;
  --handle-background-checked: #ffffff;
  // --handle-border-radius: 14px 4px 4px 14px;
  // --border-radius: 25px;
  // --handle-spacing: 1px;
}

ion-checkbox {
  --background-checked: var(--ion-color-blanco);
  --border-color: #d5d5d5;
  --border-color-checked: var(--ion-color-naranja-acento);
  --checkmark-color: var(--ion-color-naranja-acento);
  --checkmark-width: 2px;
  --size: 20px;
  --border-radius: 5px;
  --border-width: 1px;
  box-sizing: border-box;
  box-shadow: 0px 7px 15px rgba(var(--ion-color-oscuro-rgb), 0.1);

  &.checkbox-checked {
    box-shadow: 0px 5px 10px rgba(var(--ion-color-naranja-acento-rgb), 0.3);
    --checkbox-background-checked: var(--ion-color-blanco);
  }

  &.svg.checkbox-icon {
    height: 5px;
    width: 5px;
  }
}

ion-fab-button {
  height: 50px;

  &.btn-back {
    font-weight: bold;
    --background: var(--ion-color-blanco);
    --color: var(--ion-color-azul);
    --border-color: var(--ion-color-medium);
    --border-width: 1px;
    --border-style: solid;
    box-sizing: border-box;
    --box-shadow: 0px 7px 15px rgba(136, 143, 155, 0.1);
    --background-activated: #eaeff7;
    // --ripple-color:  #13274B;
    --padding-top: 24px;
    --padding-bottom: 25px;
    --padding-start: 16px;
    --padding-end: 16px;
  }

  &.btn-panic {
    font-weight: bold;
    --background: var(--ion-color-blanco);
    --color: var(--ion-color-negativo);
    //--border-color: var(--ion-color-negativo);
    //--border-width: 1px;
    //--border-style: solid;
    box-sizing: border-box;
    --box-shadow: var(--ion-color-negativo);
    --background-activated: var(--ion-color-negativo);
    // --ripple-color:  #13274B;
    // --padding-top: 24px;
    // --padding-bottom: 25px;
    // --padding-start: 16px;
    // --padding-end: 16px;
    // text-align: center;
    width: 224px;
    height: 224px;
  }
}

ion-toolbar {
  &.op-header {
    --min-height: 66px !important;
    --border-style: solid;
    --border-width: 0px;
    // --border-color: #888F9B;
    // --padding-start: 16px;
    // --padding-end: 16px;
    // --padding-bottom: 20px;
    // --padding-top: 20px;
    --background: linear-gradient(180deg, #eaeef6 0%, #ffffff 100%);

    &.toolbar-container {
      --min-height: 28px;
    }

    ion-buttons {
      --min-height: 28px;

      ion-back-button {
        // --min-width: 28px;
        --min-height: 28px;
      }
    }
  }

  &.op-header-second {
    --background: var(--ion-color-blanco);
  }
}

// ion-tab-button {
//   --min-height: 65px !important;
//   --color: #888f9b;
//   --color-selected: #213b6b;
//   font-family: SFProDisplay;
//   font-style: normal;
//   font-weight: 600;
//   font-size: 11px;
//   line-height: 13px;
//   ion-icon {
//     font-size: 30px;
//   }
//   // &.tab-selected{
//   //   ion-icon{
//   //     mask-image: url('../assets/icon/ambulance.svg');
//   //   }
//   // }
// }

ion-card {
  &.op-card-social {
    --min-height: 74px;
    background: #ffffff;
    box-shadow: 0px 4px 15px rgba(212, 212, 212, 0.4);
    border-radius: 10px;

    ion-item {
      // --border-width: 0px;
      // --inner-border-width: 0px;
      // --inner-padding-start: 19px;
      // --inner-padding-end: 0px;
      // --inner-padding-bottom: 0px;
      // --inner-padding-top: 0px;
      // --padding-start: 20px;
      --padding-end: 11px;

      ion-icon {
        margin: 0px;
      }

      ion-label {
        --color: var(--ion-color-oscuro);
      }

      //   --padding-bottom: 20px;
      // --padding-top: 22px;
      // --background: linear-gradient(180deg, #eaeef6 0%, #ffffff 100%);
      --ripple-color: #ff5912;
    }
  }
}

ion-card {
  &.op-card {
    background: #ffffff;
    box-shadow: 0px 4px 15px rgba(212, 212, 212, 0.4);
    border-radius: 10px;

    ion-item {
      --min-height: 65px;
      --border-width: 0px;
      --inner-border-width: 0px;
      --inner-padding-start: 19px;
      --inner-padding-end: 0px;
      --inner-padding-bottom: 0px;
      --inner-padding-top: 0px;
      --padding-start: 20px;
      --padding-end: 30px;

      ion-icon {
        margin: 0px;
      }

      ion-label {
        --color: var(--ion-color-oscuro);
      }

      //   --padding-bottom: 20px;
      // --padding-top: 22px;
      //   --background: linear-gradient(180deg, #eaeef6 0%, #ffffff 100%);
    }
  }
}

ion-item {
  --border-width: 0px;
  --inner-border-width: 0px;

  &.op-profile {
    ion-label {
      font-family: 'SFProDisplay' !important;
      font-style: normal;
      font-weight: 500;
      font-size: 12px !important;
      line-height: 14px;
      letter-spacing: 0.03em;
      text-transform: uppercase;
      --color: var(--ion-color-azul-grisaceo) !important;
      border-width: 0px;
    }
  }

  &.gradiente {
    font-weight: bold;
    --background: var(--ion-color-azul);
    --color: var(--ion-color-blanco);
    --box-shadow: 0px 4px 15px rgba(var(--ion-color-azul), 0.3);
    --background-activated: #ea541c;
    --ripple-color: #ea541c;
    --padding-top: 15px;
    --padding-bottom: 15px;
    --padding-start: 15px;
    --padding-end: 15px;
    height: 70px;
  }

  &.border-orange {
    background: #ffffff;
    border: 1px solid rgba(255, 89, 18, 0.5);
    box-sizing: border-box;
    box-shadow: 0px 5px 7px rgba(255, 89, 18, 0.15);
    border-radius: 10px;
  }
}

.required {
  border: 1px solid var(--ion-color-negativo) !important;
  border-radius: 10px !important;
}

.errorLabel {
  font-style: normal !important;
  font-weight: normal !important;
  font-size: 14px !important;
  line-height: 17px !important;
  color: var(--ion-color-negativo) !important;
}

ion-card {
  &.op-card-select {
    background: var(--ion-color-blanco);
    border: 1px solid #d5d5d5;
    box-shadow: 0px 0px 0px rgba(212, 212, 212, 0.4);
    border-radius: 10px;

    ion-item {
      --min-height: 65px;
      --border-width: 0px;
      --inner-border-width: 0px;
      --inner-padding-start: 9px;
      --inner-padding-end: 0px;
      --inner-padding-bottom: 0px;
      --inner-padding-top: 0px;
      --padding-start: 11px;
      --padding-end: 11px;

      ion-icon {
        margin: 0px;
      }

      ion-label {
        --color: var(--ion-color-oscuro);
      }

      //   --padding-bottom: 20px;
      // --padding-top: 22px;
      //   --background: linear-gradient(180deg, #eaeef6 0%, #ffffff 100%);
    }

    &.active {
      border: 1px solid rgba(var(--ion-color-negativo-rgb), 0.50);
      box-sizing: border-box;
      background: rgba(var(--ion-color-negativo-rgb), 0.04);
      box-shadow: 0px 7px 15px 0px rgba(159, 34, 64, 0.30);

    }
  }
}

ion-card {
  &.op-card-account {
    background: #ffffff;
    border: 1px solid #d5d5d5;
    box-shadow: 0px 7px 15px rgba(136, 143, 155, 0.1);
    border-radius: 10px;

    ion-item {
      --min-height: 65px;
      --border-width: 0px;
      --inner-border-width: 0px;
      --inner-padding-start: 19px;
      --inner-padding-end: 0px;
      --inner-padding-bottom: 0px;
      --inner-padding-top: 0px;
      --padding-start: 20px;
      --padding-end: 30px;

      ion-icon {
        margin: 0px;
      }

      ion-label {
        --color: var(--ion-color-oscuro);
      }

      //   --padding-bottom: 20px;
      // --padding-top: 22px;
      //   --background: linear-gradient(180deg, #eaeef6 0%, #ffffff 100%);
    }

    &.active {
      border: 1px solid var(--ion-color-azul-acento);
      box-sizing: border-box;
      box-shadow: 0px 7px 15px rgba(var(--ion-color-azul-acento-rgb), 0.3);
    }
  }
}

ion-card {
  &.op-alert {
    // background: #fff8f0;
    // border: 1px solid #f49739;
    // box-shadow: 0px 4px 15px rgba(230, 51, 42, 0.2);
    // border-radius: 10px;
    border-radius: 10px;
    border: 1px solid var(--ion-color-negativo);
    background: #F9F4F6;
    box-shadow: 0px 4px 15px 0px rgba(var(--ion-color-negativo-rgb), 0.30);

    ion-card-content {
      padding: 16px;
    }
  }
}

.addressItem {
  position: inherit;
  margin: 10px;
  height: 79px;
  left: 16px;
  top: 318px;
  /* Contenido / Blanco */
  background: #ffffff;
  box-shadow: 0px 4px 15px rgba(212, 212, 212, 0.4);
  border-radius: 10px;
}

.title_azul_grisaceo {
  font-family: 'SFProDisplay' !important;
  font-style: normal;
  font-weight: 500;
  font-size: 12px !important;
  line-height: 14px;
  letter-spacing: 0.03em;
  text-transform: uppercase;
  color: var(--ion-color-azul-grisaceo) !important;
  border-width: 0px;
}

.description_text {
  font-family: 'SFProDisplay' !important;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  color: var(--ion-color-azul-grisaceo) !important;
}

.op-card-select {
  background: #ffffff;
  border: 1px solid #d5d5d5;
  box-shadow: 0px 7px 15px rgba(136, 143, 155, 0.1);
  border-radius: 10px;
  margin: 15px;

  ion-icon {
    margin: 0px;
  }

  ion-label {
    --color: var(--ion-color-oscuro);
  }

  //   --padding-bottom: 20px;
  // --padding-top: 22px;
  //   --background: linear-gradient(180deg, #eaeef6 0%, #ffffff 100%);
  &.active {
    border: 1px solid rgba(255, 89, 18, 0.5);
    box-sizing: border-box;
    box-shadow: 0px 7px 15px rgba(255, 89, 18, 0.3);
  }
}

.color-div {
  width: 48px;
  height: 48px;
  left: 16px;
  top: 259px;
  text-align: center;
  /* Contenido / Blanco */
  background: #ffffff;
  /* Estatus / No_estatus */
  border: 1px solid #d5d5d5;
  box-sizing: border-box;
  box-shadow: 0px 7px 15px rgba(136, 143, 155, 0.1);
  border-radius: 10px;
  text-align: -webkit-center;

  &.active {
    border: 1px solid rgba(255, 89, 18, 0.5);
    box-sizing: border-box;
    box-shadow: 0px 7px 15px rgba(255, 89, 18, 0.3);
  }
}

.color-div-picker {
  //width: 96px;
  height: 48px;
  text-align: center;
  /* Contenido / Blanco */
  background: #ffffff;
  /* Estatus / No_estatus */
  border: 1px solid #d5d5d5;
  box-sizing: border-box;
  box-shadow: 0px 7px 15px rgba(136, 143, 155, 0.1);
  border-radius: 10px;
  display: table-cell;
  vertical-align: middle;

  &.active {
    border: 1px solid rgba(255, 89, 18, 0.5);
    box-sizing: border-box;
    box-shadow: 0px 7px 15px rgba(255, 89, 18, 0.3);
  }
}

.item-container {
  position: initial;
  width: 95%;
  max-width: 600px;
  height: auto;
  left: 10px;
  top: 501px;
  /* Contenido / Blanco */
  background: #ffffff;
  box-shadow: 0px 4px 15px rgba(212, 212, 212, 0.4);
  border-radius: 10px;
  margin-top: 7px;
  padding: 0px;
}

ion-button {
  &.button-icon-top {
    height: 5em;
    --background: #ffffff;
    --color: var(--ion-color-oscuro);
    --border-radius: 10px;
    --box-shadow: 0px 4px 15px rgba(var(--ion-color-no-estatus-rgb), 0.4);

    div {
      display: flex;
      flex-flow: column nowrap;
      align-items: center;

      ion-icon {
        margin-bottom: 10px;
        width: 23px;
        height: 23px;
      }
    }
  }
}

ion-row {
  &.full-width {
    width: 100%;
  }
}

ion-select {
  &.full {
    display: contents;
  }
}

.contenido {
  //position: absolute;
  text-align: center;
  // background-color: var(--ion-color-blanco);
  box-shadow: 0px 4px 7px rgba(212, 212, 212, 0.4);
  border-radius: 10px;
  top: 10px;
  left: 10px;
  right: 10px;
  position: absolute;
  background: #ffffff;
}

.time-control {
  //position: absolute;
  //text-align: center;
  // background-color: var(--ion-color-blanco);
  height: 45px;
  box-shadow: 0px 4px 7px rgba(212, 212, 212, 0.4);
  border-radius: 10px;
  border: 1px solid #4989ff;
}

.swipe-handler::after {
  content: '';
  width: 36px;
  height: 6px;
  position: absolute;
  left: 50%;
  top: 5%;
  margin-left: -18px;
  margin-top: -3px;
  border-radius: 3px;
  background: lightgray;
}

.button-bottom {
  bottom: 0px;
  position: fixed;
  margin-bottom: 15px;
}

.help-right-button {
  right: 15px;
  position: fixed;
}

.label-floating.sc-ion-label-ios-h {
  // margin:0px !important;
  margin-bottom: -5px !important;
  margin-top: 0px !important;
}

.item-has-focus.label-floating.sc-ion-label-ios-h,
.item-has-focus .label-floating.sc-ion-label-ios-h,
.item-has-placeholder.label-floating.sc-ion-label-ios-h,
.item-has-placeholder .label-floating.sc-ion-label-ios-h,
.item-has-value.label-floating.sc-ion-label-ios-h,
.item-has-value .label-floating.sc-ion-label-ios-h {
  transform: translate3d(0, 10px, 0) scale(0.82) !important;
}

ion-icon[name^='ios-arrow-forward'] {
  background-image: url('/assets/icon/ios-arrow-foward.svg');
}

ion-icon[name^='ios-arrow-back'] {
  background-image: url('/assets/icon/ios-arrow-back.svg');
}

ion-modal.fullscreen {
  --width: 100%;
  --height: 100%;
}

.qr-space {
  width: 100%;
  height: 100%;

  img {
    width: 70%;
  }
}

@media screen and (max-width: 480px) {
  .qr-space {
    position: relative;
    top: 60px;
  }
}